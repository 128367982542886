"use client";

import { createContext, useState, useContext,useEffect } from "react";
import axios from "axios";
const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [recentChat, setRecentChat] = useState(null);
  const [selectedThread, setSelectedThread] = useState<string>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentAssistant, setCurrentAssistant] = useState<any>(null);
  return (
    <DataContext.Provider value={{ recentChat, setRecentChat, selectedThread, setSelectedThread,currentUser,setCurrentUser, currentAssistant, setCurrentAssistant }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
